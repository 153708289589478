import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  InputNumber,
  Tabs,
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../const/history";
import { UsergroupAddOutlined } from "@ant-design/icons";
import Company from "../../Tabs/company/company";
import Project from "../../Tabs/project/project";
import Innovation from "../../Tabs/innovation/innovation";
import Startup from "../../Tabs/startup/startup";
import Invention from "../../Tabs/invention/invention";
import Product from "../../Tabs/product/product";
const { Option } = Select;

const CreateModal = (props) => {
  const { t } = useTranslation();

  const [text, setText] = useState({});

  const { id } = useParams();

  const formRef = useRef();
  const { TabPane } = Tabs;

  useEffect(() => { }, [props.worker, t]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UsergroupAddOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">
                Şirkət və fəaliyyəti haqqında məlumatlar
              </span>
            </div>
            <Link
              to={{
                pathname: `/register`,
              }}
            >
              <Button type={"primary"}>{t("cancel")}</Button>
            </Link>
          </div>
        </Col>
        <Col xs={24}>
          <Tabs>
            <TabPane tab={"Şirkət"} key={"1"}>
              <div className={`p-2 animated edit fadeInUp bg-white`}>
                <Company id={id} />
              </div>
            </TabPane>

            {id && (
              <TabPane tab={"Layihə"} key={"2"}>
                <div className={` animated edit fadeInUp `}>
                  <Project id={id} />
                </div>
              </TabPane>
            )}
            {id && (
              <TabPane tab={"İnnovasiya"} key={"3"}>
                <div className={` animated edit fadeInUp `}>
                  <Innovation id={id} />
                </div>
              </TabPane>
            )}
            {id && (
              <TabPane tab={"Startup"} key={"4"}>
                <div className={` animated edit fadeInUp `}>
                  <Startup id={id} />
                </div>
              </TabPane>
            )}

            {id && (
              <TabPane tab={"İxtira"} key={"5"}>
                <div className={` animated edit fadeInUp `}>
                  <Invention id={id} />
                </div>
              </TabPane>
            )}

            {id && (
              <TabPane tab={"Məhsul"} key={"6"}>
                <div className={` animated edit fadeInUp `}>
                  <Product id={id} />
                </div>
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { notify })(CreateModal);
