
import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Tooltip,
  Menu,
  Row,
  Col,
  Table,
  Spin,
  Select,
  Form,
  Tabs,
  Popconfirm,
  Input,
  InputNumber,
  DatePicker,
} from "antd";
import { convertColumns } from "../../../utils/columnconverter";
import {
  UsergroupAddOutlined,
  DeleteFilled,
  EditFilled,
  EyeOutlined,
  ClearOutlined,
  SettingOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logInUser, notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { aictia } from "../../../const/api";
import { useTranslation } from "react-i18next";
import excel from "../../../assets/img/excel.jpeg";
const { Option } = Select;
function IndividualMembers() {
  let mainUrl = "ReyestrOfCompany";
  const [form] = Form.useForm();
  const [individualPostList, setIndividualPostList] = useState([]);
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [input, setInput] = useState(null);
  const [page,setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [count, setCount] = useState(0);
  const pageSize =20;
  const [typeOfActivity, setTypeOfActivity] = useState([
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
  ]);
  const [packet, setPacket] = useState([]);
  const [activity, setActivity] = useState([]);
  const [columns, setColumns] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    {
      key: "nameOfCompany",
      value: t("Sahibkarlıq subyektinin adı(SSA)"),
      con: true,
    },
    {
      key: "officialCEOoftheCompany",
      value: "Subyektin Rəsmi Təmsilçi",
      con: true,
    },
    { key: "typeOfActivity", value: "Fəaliyyət Növü (F/N)", con: false },
    { key: "activity", value: "Fəaliyyət sahəsi", con: false },

    { key: "packetOfAictia", value: "Paket", con: false },
  ];
  // const columns = [
  //   {
  //     title: t("Sahibkarlıq subyektinin adı(SSA)"),
  //     dataIndex: "nameOfCompany",
  //   },
  //   {
  //     title: 'Subyektin Rəsmi Təmsilçi',
  //     dataIndex: "officialCEOoftheCompany",
  //     key: "3",
  //   },
  //   {
  //     title: 'Fəaliyyət Növü (F/N)',
  //     dataIndex: "typeOfActivity",
  //     key: "6",
  //   },
  //   {
  //     title: 'Fəaliyyət sahəsi',
  //     dataIndex: "activity",
  //     key: "8",
  //   },
  //   {
  //     title: 'Şirkətin nömrəsi',
  //     dataIndex: "phoneOfCompany",
  //     key: "9",
  //   },
  //   {
  //     title: 'Vöen',
  //     dataIndex: "tin",
  //     key: "10",
  //   },
  //   {
  //     title: 'Paket',
  //     dataIndex: "packetOfAictia",
  //     key: "10",
  //   },
  // ]
  const individualColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
      className: "tableindex",
    },
    {
      title: t("Sahibkarlıq subyektinin adı(SSA)"),
      dataIndex: "nameOfCompany",
      key: "2",
      sorter: (a, b) => a.nameOfCompany.localeCompare(b.nameOfCompany),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Subyektin Rəsmi Təmsilçi",
      dataIndex: "officialCEOoftheCompany",
      key: "3",
    },
    {
      title: "Vöen",
      dataIndex: "tin",
      key: "7",
      width: 130,
    },
    {
      title: "Fəaliyyət Növü (F/N)",
      dataIndex: "typeOfActivity",
      key: "6",
      render: (i) => {
        return <span>{i.name}</span>;
      },
    },
    {
      title: "Fəaliyyət sahəsi",
      dataIndex: "activity",
      key: "8",
      render: (i) => {
        return <span>{i.name}</span>;
      },
    },
    // {
    //   title: "Şirkətin nömrəsi",
    //   dataIndex: "phoneOfCompany",
    //   key: "9",
    //   render: (i) => {
    //     return <span>{i[0]?.value}</span>;
    //   },
    // },
    // {
    //   title: "Vöen",
    //   dataIndex: "tin",
    //   key: "10",
    // },
    // {
    //   title: "Paket",
    //   dataIndex: "packetOfAictia",
    //   key: "10",
    //   render: (i) => {
    //     return <span>{i?.name ? i.name : "Paket seçilməyib"}</span>;
    //   },
    // },
    {
      title: "",
      dataIndex: "id",
      key: "",
      render: (i, index) => {
        return (
          <div className="flex flex-end">
            {
              role === 'Admin'&&(
                <>
                <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/register/edit/${i}`,
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePost(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
                </>
              )
            }
            
            <Tooltip
              className="ml-5"
              title={t("detailed")}
              placement="topRight"
            >
              <Link
                to={{
                  pathname: `/register/view/${i}`,
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EyeOutlined />
                </Button>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const deletePost = async (i) => {
    if (i === 0 || i) {
      await aictia
        .delete(`${mainUrl}/${i}`)
        .then((res) => {
          getRegister();
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };
  const getRegister = (paramss) => {
    setSpin(true);
    const params = {
      PerDataCount: (page-1) * pageSize,
      GetDataCount: pageSize,
      ...paramss
    }
    aictia.get("ReyestrOfCompany",{params}).then((res) => {
      res.data && setSpin(false);
      console.log(page);
      
      setIndividualPostList(
        res.data.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex:  res.data.count - index - (page-1)*pageSize ,
          };
        })
      );
      setCount(res.data.count)
    });
  };
  const onSearch = (values) => {

    const params = {
      TypeOfActivityId: values?.typeOfActivity,
      ActivityId: values?.activity,
      NameOfCompany: values?.nameOfCompany,
      OfficialCEOoftheCompany: values?.officialCEOoftheCompany,
      Tin: values?.tin,

    }
    setFilter(params);
    getRegister(params)
   // setIndividualPostList(filteredData);
  };
  const clearFilter = () => {
    form.resetFields();
    setInput(null);
    setFilter({});
    getRegister();
  };
  const getTypeOfActivity = () => {
    aictia.get("TypeOfActivity").then((res) => {
      setTypeOfActivity(res.data);
    });
  };
  const getActivity = () => {
    aictia.get("Activity").then((res) => {
      setActivity(res.data);
    });
  };
  const getAictiaPacket = () => {
    aictia.get("PacketOfAictia").then((res) => {
      setPacket(res.data);
    });
  };
  const columnsData = (e) => {
    //  setIndividualColumns(columns.map((p, index) => {
    //   if (e.includes(p.dataIndex)) {
    //     return p
    //   }else{
    //     return null
    //   }
    // }))
    setColumns(
      individualColumns.filter((p, index) => {
        if (e.includes(p.dataIndex)) {
          return p;
        }
        if (e.length === 0) {
          return individualColumns;
        }
        if (p.dataIndex === "tableIndex") {
          return p;
        }
        if (p.dataIndex === "id") {
          return p;
        }
      })
    );
    setTrigger(trigger + 1);
  };
  const chooseInput = () => {
    switch (input) {
      case 1:
        return (
          <Col md={4} sm={12} xs={24}>
            <Form.Item
              name="nameOfCompany"
              className={"mb-0"}
              // rules={[whiteSpace(t("inputError"))]}
            >
              <Input placeholder={t("Axtar")} size={"large"} />
            </Form.Item>
          </Col>
        );
      case 2:
        return (
          <Col md={4} sm={12} xs={24}>
            <Form.Item
              name="officialCEOoftheCompany"
              className={"mb-0"}
              // rules={[whiteSpace(t("inputError"))]}
            >
              <Input
                placeholder={t("Subyektin Rəsmi Təmsilçi")}
                size={"large"}
              />
            </Form.Item>
          </Col>
        );
   
        case 3:
          return (
            <Col md={4} sm={12} xs={24}>
              <Form.Item
                name="tin"
                className={"mb-0"}
                // rules={[whiteSpace(t("inputError"))]}
              >
                <Input
                  placeholder={t("Vöen")}
                  size={"large"}
                />
              </Form.Item>
            </Col>
          );
  
      default:
        return null;
    }
  };
  useEffect(() => {
    getTypeOfActivity();
    getActivity();
    getAictiaPacket();
  }, []);
  useEffect(() => {
    getRegister(filter);
  }
  , [page]);
console.log(individualPostList);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UsergroupAddOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{t("Reyester")}</span>
            </div>

         
              <Form className="register-head" form={form}>
                <div className="register">
                  <label className="labelPlace">
                    {" "}
                    <SettingOutlined />
                  </label>

                  <Select
                    dropdownMatchSelectWidth={false}
                    showSearch={false}
                    mode="multiple"
                    onChange={(e) => columnsData(e)}
                  >
                    {individualColumns.map((p, index) => {
                      return (
                        p.dataIndex !== "tableIndex" &&
                        p.dataIndex !== "id" && p.dataIndex!=="packetOfAictia" && (
                          <Option key={index} value={p.dataIndex}>
                            {p.title}
                          </Option>
                        )
                      );
                    })}
                  </Select>
                </div>

                <div>
                  <Button className="file-icon">
                    <img src={excel} />
                  </Button>
                </div>
                {
                  role === 'Admin'&&(
                    <div>
                    <Link
                      to={{
                        pathname: `register/create`,
                        state: { locales: null },
                      }}
                    >
                      <Button type="primary">{t("addTo")}</Button>
                    </Link>
                  </div>
                  )
                }
              
              </Form>
         
          </div>
        </Col>



        <Col xs={24}>
          <Form onFinish={onSearch} layout="vertical" form={form}>
            <div className="commontask bg-white px-2 pt-15">
              <Row className={"mt-5"} gutter={[16, 8]}>
                <Col md={4} sm={12} xs={24}>
                  <Form.Item
                    name={"CheckInput"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select
                      onChange={(e) => setInput(e)}
                      placeholder={t("Axtarış növü seç")}
                    >
                      <Option key={1} value={1}>
                        {t("Sahibkarlıq subyektinin adı(SSA)")}
                      </Option>
                      <Option key={2} value={2}>
                        {t("Subyektin Rəsmi Təmsilçi")}
                      </Option>
                      <Option key={3} value={3}>
                        {t("Vöen")}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {chooseInput()}
                <Col md={4} sm={12} xs={24}>
                  <Form.Item
                    name={"typeOfActivity"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select placeholder={t("Fəaliyyət Növü (F/N)")}>
                      {typeOfActivity.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={4} sm={12} xs={24}>
                  <Form.Item
                    name={"activity"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select placeholder={t("Fəaliyyət sahəsi")}>
                      {activity.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
               
                <Col md={2} sm={12} xs={24}>
                  <Form.Item name={" "}>
                    <Button
                      type="primary"
                      size={"large"}
                      className="w-100 f-13"
                      htmlType="submit"
                    >
                      {t("search")}
                    </Button>
                  </Form.Item>
                </Col>

                <Col md={2} sm={12} xs={24}>
                  <Form.Item name={" "}>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={() => {
                        clearFilter();
                      }}
                      className="w-100"
                      htmlType="submit"
                    >
                      <ClearOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={trigger === 0 ? individualColumns : columns}
                dataSource={convertColumns(individualPostList, cols)}
                pagination={{
                  pageSize: pageSize,
                  current: page,
                  total: count,
                  onChange: (page) => setPage(page),
                }}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}

export default connect(null, { notify })(IndividualMembers);
