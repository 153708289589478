import axios from "axios";
import siteUrl from "./const";

export const aictia = axios.create({
  baseURL: `${siteUrl}`,
  headers: { "Content-Type": "application/json" },
});

aictia.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = localStorage.getItem("access_token")
      ? "Bearer " + localStorage.getItem("access_token")
      : null;
    config.headers["accept-language"] = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "az";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




// kef ele supersen
aictia.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.response.status);
    
    const errorMessage = error.response?.data || "An unknown error occurred";

    if (error.response?.status === 401) {
      localStorage.removeItem("access_token");
      //window.location.href = "/login";
    }

    return Promise.reject(errorMessage);
  }
);


export default aictia;
