import React, { useEffect, useState, useRef } from "react";
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebook, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import { BsLinkedin, BsWikipedia } from "react-icons/bs";

function ContactCard({ data }) {
  const [activeIconCompany, setActiveIconCompany] = useState(null);
  const [activeIconCeo, setActiveIconCeo] = useState(null);
  const [urlInfo, setUrlInfo] = useState(false);
  const [urlInfoCeo, setUrlInfoCeo] = useState(false);

  // Icon'a tıklandığında çalışacak fonksiyon

  const icons = [
    { id: 1, icon: <PhoneOutlined /> },
    { id: 2, icon: <MailOutlined /> },
    { id: 3, icon: <GlobalOutlined /> },
    { id: 4, icon: <UserOutlined /> },
  ];
  const socialMediaMap = [
    { id: 1, icon: <FaFacebook className="social-icon" />, name: "Facebook" },
    { id: 2, icon: <FaTwitter className="social-icon" />, name: "Twitter" },
    { id: 3, icon: <BsLinkedin className="social-icon" />, name: "Linkedin" },
    { id: 4, icon: <AiFillYoutube className="social-icon" />, name: "Youtube" },
    { id: 5, icon: <BsWikipedia className="social-icon" />, name: "Wikipedia" },
  ];

  const socialMedia = () => {
    if (data?.socialNetworkofCompany) {
      const hasInfo = data?.socialNetworkofCompany.some((item) => item.value);
      setUrlInfo(hasInfo);
    }
    if (data?.socialNetworkofCeo) {
      const hasInfoCeo = data?.socialNetworkofCeo.some((item) => item.value);
      setUrlInfoCeo(hasInfoCeo);
    }
  };

  useEffect(() => {
    socialMedia();
  }, [data]);

  const handleIconCompany = (iconId) => {
    setActiveIconCompany(activeIconCompany === iconId ? null : iconId); // tıklanan icon aktifse deaktif et, değilse aktif et
    setActiveIconCeo(null)
  };
  const handleIconCeo = (iconId) => {
    setActiveIconCeo(activeIconCeo === iconId ? null : iconId); // tıklanan icon aktifse deaktif et, değilse aktif et
    setActiveIconCompany(null)
  };


  return (
    <div className="contact-detail">
      <div className="contact">
        <h2 className="mb-20">Şirkət ilə əlaqə</h2>
        <div className="icons">
          {icons.map((icon) => (
            <div
              key={icon.id}
              className={`icon ${
                activeIconCompany &&
                ` ${activeIconCompany === icon.id ? "aktiv" : "deaktiv"}`
              }`}
              onClick={() => handleIconCompany(icon.id)}
            >
              {icon.icon}
            </div>
          ))}
        </div>

        <div>
          {activeIconCompany === 1 && (
            <div className={`info ` }>
              {data?.phoneOfCompany?.map((elem, index) => (
                <p key={index} className="">
                  {elem.value ? elem.value : "əlaqə nömrəsi yoxdur"}
                </p>
              ))}
            </div>
          )}

          {activeIconCompany === 2 && (
            <div className="info">
              {data?.officialMail?.map((elem, index) => (
                <p key={index} className="">
                  {elem.value ? elem.value : "məlumat yoxdur"}
                </p>
              ))}
            </div>
          )}

          {activeIconCompany === 3 && (
            <div className="info">
              {data?.officialWebSite ? (
                <a
                  target="_blank"
                  href={data?.officialWebSite}
                  rel="noreferrer noopener "
                >
                  {data?.officialWebSite}
                </a>
              ) : (
                " məlumat yoxdur"
              )}
            </div>
          )}
          {activeIconCompany === 4 && (
            <div className="info">
              {urlInfo ? (
                <div className="icons-links">
                  {data?.socialNetworkofCompany.map((elem, index) =>
                    socialMediaMap.map(
                      (item) =>
                        elem.type == item.id &&
                        elem.value && (
                          <a
                            target="_blank"
                            href={elem.value}
                            rel="noreferrer noopener "
                            // className="social-icon"
                            key={index}
                          >
                            {item.icon}
                          </a>
                        )
                    )
                  )}
                </div>
              ) : (
                "məlumat yoxdur"
              )}
            </div>
          )}
        </div>
      </div>
      <div className="contact">
        <h2 className="mb-20">Rəhbər ilə əlaqə</h2>
        <div className="icons">
          {icons
            .map((icon) => (
             icon.id !== 3 &&
              <div
                key={icon.id}
                className={`iconCeo  ${
                  activeIconCeo &&
                  ` ${activeIconCeo === icon.id ? "aktiv" : "deaktiv"}`
                }`}
                onClick={() => handleIconCeo(icon.id)}
              >
                {icon.icon}
              </div>
            ))}
       
        </div>

        <div>
          {activeIconCeo === 1 && (
            <div className="info">
              {data?.phoneOfCeo?.map((elem, index) => (
                <p key={index} className="">
                  {elem.value ? elem.value : "əlaqə nömrəsi yoxdur"}
                </p>
              ))}
            </div>
          )}

          {activeIconCeo === 2 && (
            <div className="info">
              {data?.mailOfCeo.map((elem, index) => (
                <p key={index} className="">
                  {elem.value ? elem.value : "məlumat yoxdur"}
                </p>
              ))}
            </div>
          )}

          {activeIconCeo === 4 && (
            <div className="info">
              <div className="icons-links">
                {urlInfoCeo ? (
                  <div className="icons-links">
                    {data?.socialNetworkofCeo.map((elem, index) =>
                      socialMediaMap.map(
                        (item) =>
                          elem.type == item.id &&
                          elem.value && (
                            <a
                              target="_blank"
                              href={elem.value}
                              rel="noreferrer noopener "
                              // className="social-icon"
                              key={index}
                            >
                              {item.icon}
                            </a>
                          )
                      )
                    )}
                  </div>
                ) : (
                  "məlumat yoxdur"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
