

import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, Upload,
    Select,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
    ToolFilled
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../../../utils/rules";
const Users = (props) => {
    const mainUrl = "User"
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const [reset, setReset] = useState(null)
    const [roles, setRoles] = useState([]);
    const [message, setMessage] = useState(null)
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "userName", value: t("name"), con: true },
        { key: "image", value: "Şəkil", con: true },
        { key: "id", value: "", con: true },
    ];
    const nameInput = useRef();
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: "Ad",
            dataIndex: "fullName",
            key: "10"
        },
        {
            title: "İstifadəçi adı",
            dataIndex: "username",
            key: "10"
        },
        {
            title: "Səlahiyyəti",
            dataIndex: "roleName",
            key: "10"
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deleteUser(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingUser(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                        <Tooltip className="ml-5" title={'Şifrəni yenilə'} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => {
                                    setReset(i);
                                    setEditing(null)
                                }}
                            >
                                <ToolFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const setEditingUser = async (i) => {
        setReset(false);
        setEditing(i);
        await aictia.get(`${mainUrl}/${i}`).then((res) => {
            let obj = {};
            obj['FullName'] = res.data.fullName;
            obj['roleId'] = res.data.roleId;
            // setActive(names.is_active === 1)
            form.setFieldsValue(obj);
        });
    };

    const deleteUser = async (i) => {
        await aictia

            .delete(`${mainUrl}/${i}`)
            .then((res) => {
                notify("", true);
                getUsers();
            })
            .catch((err) => {
                notify(err.response, false);
            });
    };


    const cancelEditing = () => {
        setEditing(null);
        setReset(false)
        form.resetFields();
    };
    const savePosition = async (values) => {
        let obj = {
            fullName: values.FullName,
            userName: values.UserName,
            password: values.Password,
            roleId: values.roleId
        };
        if (!editing) {
            await aictia
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getUsers();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
            }
        else {
            obj.id = editing;
            await aictia
                .put(`${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getUsers();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }

    };

    const getUsers = async () => {
        setSpin(true);
        await aictia.get(mainUrl, { params: { per_page: 20000 } }).then((res) => {
            setSpin(false);
            setUsers(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        // name: p.locales.find((l) => {
                        //     return l.local === localStorage.getItem("locale");
                        // }).name,
                    };
                })
            );
        });
    };

    const getRoles = async () => {
        await aictia.get("Role").then((res) => {
            setRoles(
                res.data
            );
        }
        );
    };
const resetPassword = async (values) => {
    if (values.Password !== values.repetitivePassword) {
        setMessage("Şifrələr uyğun gəlmir");
        return;
    }
    let obj = {
        password: values.Password,
        repetitivePassword: values.repetitivePassword,
        id: reset
    };
    await aictia

        .put(`${mainUrl}/ResetPassword/${reset}`, obj)
        .then((res) => {
            notify("", true);
            setMessage(null)
            getUsers();
            cancelEditing();
        })
        .catch((err) => {
            notify(err.response, false);
        });
};


    useEffect(() => {
        getUsers();
        getRoles();
    }, [t]);

;


    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">İstifadəçilər</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(users, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: users.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    {
                        !reset ?
                        <Form layout="vertical" onFinish={savePosition} form={form}>
                        <div className="form-lang">
                            <div className="mb-10">
                                Ad, soyad, ata adı
                            </div>
                            <Form.Item
                                name={'FullName'}
                                validateTrigger="onChange"
                                // getValueFromEvent={(event, editor) => {
                                //     const dat = editor.getData();
                                //     return dat;
                                // }}
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input ref={nameInput} />

                            </Form.Item>
                        </div>
                        {
                            !editing &&
<div className="form-lang">
                            <div className="mb-10">
                                İstifadəçi adı
                            </div>
                            <Form.Item
                                name={'UserName'}
                                validateTrigger="onChange"
                                // getValueFromEvent={(event, editor) => {
                                //     const dat = editor.getData();
                                //     return dat;
                                // }}
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input ref={nameInput} />
                            </Form.Item>
                        </div>
                        }
                        {
                            !editing &&
                            <div className="form-lang mb-20">
                            <div className="mb-10">
                                Şifrə
                            </div>
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name="Password"
                                rules={[
                                    whiteSpace(t("addPasswordError")),
                                    {
                                        min: 6,
                                        message: t("minimumPasswordError"),
                                    },
                                ]}
                            >
                                <Input.Password autoComplete="off" />
                            </Form.Item>
                            {message && <div className="text-danger">{message}</div>}
                        </div>
                        }

                              
                                <div className="form-lang">
                            <div className="mb-10">
                                Səlahiyyəti
                            </div>
                            <Form.Item
                                name={'roleId'}
                                validateTrigger="onChange"
                                // getValueFromEvent={(event, editor) => {
                                //     const dat = editor.getData();
                                //     return dat;
                                // }}
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select>
                                    {roles.map((p) => {
                                        return (
                                            <Select.Option key={p.id} value={p.id}>
                                                {p.name}
                                            </Select.Option>
                                        );
                                    }
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                    :
                    <Form layout="vertical" onFinish={resetPassword} form={form}>
                        <div className="form-lang">
                            <div className="mb-10">
                                Şifrə
                            </div>
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name="Password"
                                rules={[
                                    whiteSpace(t("addPasswordError")),
                                    {
                                        min: 6,
                                        message: t("minimumPasswordError"),
                                    },
                                ]}
                            >
                                <Input.Password autoComplete="off" />
                            </Form.Item>
                            {message && <div className="text-danger">{message}</div>}
                        </div>
                        <div className="form-lang">
                            <div className="mb-10">
                                Təkrar şifrə
                            </div>
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name="repetitivePassword"
                                rules={[
                                    whiteSpace(t("addPasswordError")),
                                    {
                                        min: 6,
                                        message: t("minimumPasswordError"),
                                    },
                                ]}
                            >
                                <Input.Password autoComplete="off" />
                            </Form.Item>
                            {message && <div className="text-danger">{message}</div>}
                        </div>
                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                    }
                           
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Users);